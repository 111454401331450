import Link from 'next/link'
import { useState } from 'react'
import { FiArrowRight, FiCheckCircle } from 'react-icons/fi'
import tw from 'tailwind-styled-components'

const Wrapper = tw.div`
  px-4
  py-2
  mb-4
`

const Title = tw.h2`
  font-serif
  text-2xl
  md:text-4xl
  mb-2
`

const InnerWrapper = tw.div`
  grid
  gap-4
  md:grid-cols-[1fr_0.64fr]
  justify-center
  items-center
`

const TextWrapper = tw.div``

const Text = tw.p``

const FormWrapper = tw.div`
  bg-teatips-light
  rounded
  p-4
`

const MailLabel = tw.label`
  mb-1
  block
`

const ControlsWrapper = tw.div`
  grid
  grid-cols-[1fr_auto]
  border-2
  border-teatips
  rounded
  mb-1
`

const MailInput = tw.input`
  mb-2
  h-full
  rounded
  px-4
  py-2
`

interface SubmitButtonProps {
  $email: string | undefined
}

const SubmitButton = tw.button<SubmitButtonProps>`
  bg-teatips
  border-l-2
  border-teatips
  px-4
  ${({ $email }) => ($email ? '' : 'grayscale pointer-events-none')}
`

const Newsletter = () => {
  const [email, setEmail] = useState('')
  const [subscribed, setSubscribed] = useState(false)

  const subscribe = (email: string) => {
    if (subscribed) return

    fetch('/api/marketing/newsletter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })

    setSubscribed(true)
  }

  return (
    <Wrapper>
      <Title>Ricevi le novità</Title>

      <InnerWrapper>
        <TextWrapper>
          <Text>
            Una newsletter per non perderti gli aggiornamenti sulla tua
            selezione di tè preferita.
          </Text>

          <Text>
            Nuovi prodotti, promozioni, articoli di blog e curiosità legati al
            mondo di Teatips.
          </Text>

          <Text>La tua posta non si riempirà di spam, ma solo di buon tè.</Text>
        </TextWrapper>

        <FormWrapper>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              subscribe(email)
            }}
          >
            <MailLabel htmlFor="email">Inserisci la tua email</MailLabel>
            <ControlsWrapper>
              <MailInput
                type="email"
                placeholder="esempio@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <SubmitButton
                aria-label="Iscriviti"
                onClick={() => subscribe(email)}
                type="button"
                $email={email}
              >
                {subscribed ? <FiCheckCircle /> : <FiArrowRight />}
              </SubmitButton>
            </ControlsWrapper>

            <div className="text-xs">
              Iscrivendoti accetti la{' '}
              <Link href="/pages/privacy-policy">
                <a>Privacy policy</a>
              </Link>
            </div>
          </form>
        </FormWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

export default Newsletter
