import {
  FiBookOpen,
  FiMail,
  FiMessageSquare,
  FiUserCheck,
} from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'
import { useContext } from 'react'
import { ChatContext } from '../providers/ChatContext'
import Link from 'next/link'

const GetInTouch = () => {
  const { dispatch } = useContext(ChatContext)

  return (
    <div className="px-4 mb-4">
      <h2 className="font-serif text-2xl md:text-4xl mb-2">
        Informazioni e Contatti
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-[1fr_0.64fr] gap-4">
        <div>
          <h3>Spedizione: tempi e costi</h3>
          <p>
            La spedizione è gratuita sopra i{' '}
            {Intl.NumberFormat('it-IT', {
              currency: 'EUR',
              currencyDisplay: 'symbol',
              unitDisplay: 'long',
              minimumFractionDigits: 0,
              style: 'currency',
            }).format(
              parseFloat(process.env.NEXT_PUBLIC_STANDARD_FREE_SHIPPING)
            )}{' '}
            di spesa e arriva in 4 giorni lavorativi, se spendi meno di{' '}
            {Intl.NumberFormat('it-IT', {
              currency: 'EUR',
              currencyDisplay: 'symbol',
              unitDisplay: 'long',
              minimumFractionDigits: 0,
              style: 'currency',
            }).format(
              parseFloat(process.env.NEXT_PUBLIC_STANDARD_FREE_SHIPPING)
            )}{' '}
            il costo delle spedizioni standard è di{' '}
            {Intl.NumberFormat('it-IT', {
              currency: 'EUR',
              currencyDisplay: 'symbol',
              unitDisplay: 'long',
              minimumFractionDigits: 0,
              style: 'currency',
            }).format(5)}
            .
          </p>
          <p className="mb-4">
            Se hai fretta e vorresti ricevere il tuo ordine più rapidamente,
            prima del pagamento puoi selezionare le spedizioni Express a{' '}
            {Intl.NumberFormat('it-IT', {
              currency: 'EUR',
              currencyDisplay: 'symbol',
              unitDisplay: 'long',
              minimumFractionDigits: 0,
              style: 'currency',
            }).format(8)}
            : il tuo ordine arriverà entro 48 ore.
          </p>

          <h3>Hai trovato quello che cercavi?</h3>
          <p>
            Se sei alla ricerca di un regalo, visita la sezione{' '}
            <Link href={'/collections/idee-regalo'}>Idee Regalo</Link> e
            lasciati ispirare.
          </p>
          <p className="mb-4">
            Se stai cercando qualcosa di personalizzato, puoi contattarmi
            tramite{' '}
            <span
              className="cursor-pointer underline text-text"
              onClick={() => dispatch({ type: 'OPEN' })}
            >
              Chat
            </span>{' '}
            su <a href="https://wa.me/393518770830">Whatsapp</a> per costruire
            un prodotto su misura insieme.
          </p>

          <h3>Hai bisogno di assistenza per il tuo ordine?</h3>
          <p>
            Scrivimi in chat per sapere dove si trova il tuo ordine e tra quanto
            lo riceverai: ricordati di specificare il numero d&apos;ordine, così
            potrò aiutarti più velocemente.
          </p>
        </div>
        <div className="flex items-center">
          <div className="w-full grid grid-cols-[auto_1fr] gap-x-2 gap-y-2 items-center md:mb-4 bg-teatips-light rounded p-4">
            <FiUserCheck />
            <span
              className="cursor-pointer underline text-text"
              onClick={() => dispatch({ type: 'OPEN' })}
            >
              Contattami in chat
            </span>
            <FaWhatsapp />
            <a href="https://wa.me/393518770830">Scrivimi su Whatsapp</a>
            <FiMail />
            <a href="mailto:info@teatips.it">Scrivimi una email</a>
            <FiMessageSquare />
            <Link href={`/pages/domande-frequenti`}>
              <a>Domande frequenti</a>
            </Link>
            <FiBookOpen />
            <Link href={`/pages/condizioni-acquisto`}>
              <a>Consulta le condizioni di acquisto</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetInTouch
