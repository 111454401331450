import { FaFacebook, FaInstagram, FaPinterest, FaTiktok } from 'react-icons/fa'
import tw from 'tailwind-styled-components'
import GetInTouch from '../GetInTouch'
import Newsletter from '../Newsletter'

const ShopFooter = () => {
  return (
    <Footer>
      <Wrapper>
        <GetInTouch />

        <Newsletter />
      </Wrapper>

      <BottomWrapper>
        <InnerBottomWrapper>
          <SocialWrapper>
            <Link
              href="https://www.instagram.com/theteatips/"
              rel="noreferrer noopener"
              target="_blank"
              aria-label="Profilo Instagram"
            >
              <FaInstagram className="text-background text-2xl" />
            </Link>
            <Link
              href="https://www.facebook.com/theteatips"
              rel="noreferrer noopener"
              target="_blank"
              aria-label="Pagina Facebook"
            >
              <FaFacebook className="text-background text-2xl" />
            </Link>
            <Link
              href="https://tiktok.com/@quella_del_te"
              rel="noreferrer noopener"
              target="_blank"
              aria-label="Profilo Tiktok"
            >
              <FaTiktok className="text-background text-2xl" />
            </Link>
            <Link
              href="https://www.pinterest.it/theteatips/"
              rel="noreferrer noopener"
              target="_blank"
              aria-label="Pagina Pinterest"
            >
              <FaPinterest className="text-background text-2xl" />
            </Link>
          </SocialWrapper>

          <Bottom>
            &copy; {new Date().getFullYear()} Teatips
            <br />
            IT05241020287 - M5UXCR1
          </Bottom>
        </InnerBottomWrapper>
      </BottomWrapper>
    </Footer>
  )
}

const Footer = tw.footer`
  clear-both
`

const Wrapper = tw.div`
  container
  mx-auto
`

const BottomWrapper = tw.div`
  bg-text
  p-4
`
const InnerBottomWrapper = tw.div`
  container
  mx-auto
`

const SocialWrapper = tw.div`
  bg-text
  grid
  grid-flow-col
  auto-cols-max
  gap-2
  items-center
  justify-center
  mb-2
`

const Link = tw.a`
  w-[48px]
  h-[48px] 
  flex 
  justify-center 
  items-center
`

const Bottom = tw.div`
  bg-text
  grid
  grid-flow-col
  auto-cols-max
  gap-2
  items-center
  justify-center
  text-background
  text-center
  text-xs
`

export default ShopFooter
